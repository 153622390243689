.leftlayoutContainer {
	min-height: 80px;
	max-height: 80px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.logo {
	height: 38px;
	width: 149px;
}

.logoContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 10%;
}

.titleContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 2s;
    text-align: center;
	font-weight: bold;
	font-size: x-large;
	width: 80%;
	color: #6d6e71;
}

.buttonContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 10%;
}

.selectionContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 2s;
}